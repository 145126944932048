<template>
	<div class="other-faq">
		<v-row>
			<v-col cols="12">
				<v-card class="" outlined>
					<v-card-title class="text-h4 ">
						Wholesale FAQ And Additional Information
					</v-card-title>

					<v-card-text class="mt-2 ">

						<v-expansion-panels
							class="text-body-1"
							focusable
						>
							<v-expansion-panel class="">
								<v-expansion-panel-header class="text-body-1">
									What is the minimum order I need to place for a wholesale order?
								</v-expansion-panel-header>
								<v-expansion-panel-content class="">
									Currently, there is no minimum, meaning you can order as little as a
									single item.
									The discount you get is based on ordered items value so it may prove
									more beneficial
									to place a single big order rather than multiple small ones.
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header class="text-body-1">
									Do you still have vases and spare parts for the previous
									model?
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									Yes, the spare parts and vases on “T&G” connection are still part of
									our
									offer. If
									you wish to purchase any of these items, please make a note in the
									“Additional
									information” section of your order and we will add it to your
									composition.
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header class="text-body-1">
									How many WOOKAH shishas can fit on a single pallet?
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									For road transport the pallet size allows for a maximum of <strong>60
									sets</strong>.
									For all air or
									sea freights plastic pallets are used with maximum capacity of
									<strong>48
										sets</strong>.
									<br>
									Orders smaller than 18 sets will be sent in one or more parcels of
									<strong>up to
										30kg each</strong>.
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header class="text-body-1">
									Can I collect goods with my own transport?
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									Currently we use an appointed forwarding company to ship
									the goods and self-collection is not possible. The quote will be
									provided after the
									order is placed according to its volume.
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header class="text-body-1">
									My country’s customs requires additional documents for importing goods. How can they
									be issued?
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									Before the order is placed, please inform us which additional documents are
									required. One frequently requested document is a Certificate of Origin, which can be
									issued on demand. Note: additional fees may apply depending on
									the document type.
									<br>
									<br>
									Please contact us beforehand to make sure that proper documentation will be issued
									without additional delay.
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header class="text-body-1">
									I have placed an order and would like to know more about the discount I get. Can
									you provide me with more information?
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									The discount level is calculated based on the total retail value of the products.
									After reaching a certain tier, respective discount will be automatically applied to
									your order.

									<v-sheet max-width="600" class="my-4">
										<v-data-table
											:headers="headers"
											:items="items"
											hide-default-footer
											class="elevation-1"
										>
											<template v-slot:item.desc="{ item }">
												<div v-html="item.desc" />
											</template>
										</v-data-table>
									</v-sheet>

									Once the order is confirmed, you will receive an email from our representative with
									the exact calculation including transport costs.
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header class="text-body-1">
									How quickly will I receive my goods?
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									The waiting time depends on multiple factors, such as how quickly we receive your
									payment, the current production queue and the shape of your order. After the payment
									we will be able to give you a time estimate. Please note the shipping time can vary
									and depends on your location - a tracking number will be provided once the goods
									enter the shipping stage.
								</v-expansion-panel-content>
							</v-expansion-panel>

						</v-expansion-panels>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

	</div>
</template>
<script>

export default {
	name: 'OtherHelp',
	components: {},
	computed: {},
	data: () => ({
		panel: null,

		headers: [
			{text: 'Total Retail Value',value: 'value', sortable: false, width: 150},
			{text: 'Discount',value: 'discount', sortable: false},
			{text: ' ',value: 'desc', sortable: false},
		],
		items: [
			{
				value: '€0-2500',
				discount: 'None',
				desc: 'The final amount will be reduced by <strong>23%</strong> for all export and customers with valid VAT EU. (tax value will be removed after placing an order)',
			},
			{
				value: '€2500-7000',
				discount: 'DL#1 ▂',
				desc: 'Price will be calculated in net values. (no tax)',
			},
			{
				value: '€7000-18000',
				discount: 'DL#2 ▅',
				desc: 'Price will be calculated in net values. (no tax)',
			},
			{
				value: '€18000+',
				discount: 'DL#3 ▇',
				desc: 'Price will be calculated in net values. (no tax)',
			},
		],
	}),
	methods: {},
	created() {
	}
};
</script>
